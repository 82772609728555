import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Header from "../../components/Header"

export default () => (
	<Layout>
		<SEO pageTitle="Privacy policy" />
		<Header pageTitle="Privacy policy" bgImage="backgrounds/header.jpg" />
		<section className="body-text">
			<div className="container">
				<h2>1. Introduction</h2>
				<p>This privacy notice provides you with the details of how we collect and process your personal data through your use of our website, or social media pages, including any information you may provide through our sites when you purchase a product or service, sign up to our newsletter or take part in a prize draw or competition. By providing us with your data you weren&apos;t towards that you are over 13 years of age. <strong>Birds of Beauty Parlour</strong> is the data controller and we are responsible for your personal data (referred to as &lsquo;we&rsquo;, &lsquo;us&rsquo;, or &lsquo;our&rsquo; in this privacy notice). </p>
				<h3>Contact Details</h3>
				<p>Our full details are: </p>
				<p>Full name of legal entity: <strong>Birds of Beauty Parlour</strong><br />Email address: <a href="mailto:hello@birdsofbeautyparlour.co.uk">hello@birdsofbeautyparlour.co.uk</a><br />Postal address: 21 Westdale Crescent, Carlton, Nottingham, NG4 3JP</p>
				<p>If you are not happy with any aspect of how we collect and use your data, you have the right to complain to the Information Commissioner&rsquo;s Office (ICO), the UK supervisory authority for data protection issues (<a href="http://www.ico.org.uk">www.ico.org.uk</a>). We would be grateful if you would contact us first if you do have a complaint so that we can try to resolve it for you. It is very important that the information we hold about you is accurate and up to date. Please let us know if at any time your personal information changes by emailing us at <a href="mailto:hello@birdsofbeautyparlour.co.uk">hello@birdsofbeautyparlour.co.uk</a>.</p>
				<h2>2. What data do we collect about you, for what purpose and on what grounds do we process it?</h2>
				<p>Personal data means any information capable of identifying an individual. It does not include anonymised data. We may process the following categories of personal data about you as follows:</p>
				<ul>
						<li><strong>Identity Data</strong> may include your first name, maiden name, last name, username, marital status, title, date of birth and gender pro-noun.</li>
						<li><strong>Contact Data</strong> may include your billing address, email address and telephone numbers.</li>
						<li><strong>Financial data</strong> may include your payment card details.</li>
						<li><strong>Technical data</strong> may include your login data, Internet Protocol addresses, browser type and versions, browser plugin types and versions, time zone setting and location, operating system and platform and other technology on the devices you use to access this site.</li>
						<li><strong>Profile Data</strong> may include your username and password, purchases or orders, your interests, preferences, feedback and survey responses.</li>
						<li><strong>Usage Data</strong> may include information about how you use our website, products or services.</li>
						<li><strong>Marketing and Communications Data</strong> may include your preferences in receiving marketing communications from us and our third parties and your communication preferences.</li>
				</ul>
				<p>We may also process <strong>Aggregated Data</strong> from your personal data, but this data does not reveal your identity and as such in itself is not personal data. An example of this is where we review your Usage Data to work out the percentage of website users using a specific feature of our site. If we link the Aggregated Data with your personal data so that you can be identified from it, then it is treated as personal data. </p>
				<p><strong>Sensitive Data</strong> We may need to collect the following sensitive data about you in order to deliver certain treatments or services within the clinic: <em>certain details pertaining to your medial history.</em> We require your explicit consent for processing sensitive data, so when you submit your details, we will send you a further communication asking for you to confirm your consent to this processing. Where we are required to collect personal data by law, or under the Terms of the Contract between us and you, and you do not provide us with that data when requested, we may not be able to perform the contract (for example, to deliver goods or services to you). If you don&rsquo;t provide us with the requested data, we may have to cancel a product or service you have ordered but if we do, we will notify you at the time.</p>
				<h2>3. How we collect your personal data</h2>
				<p>We collect data about you through a variety of different methods including:</p>
				<ul>
						<li><strong>Direct interactions:</strong> You may provide data by filling in forms on our site (or otherwise) or by communicating with us by post, phone, email or otherwise, including when you:<ul>
										<li>order our products or services;</li>
										<li>create an account on our site;</li>
										<li>subscribe to our service or publications;</li>
										<li>request resources or marketing be sent to you;</li>
										<li>enter a competition, prize draw, promotion or survey; or</li>
										<li>give us feedback.</li>
								</ul>
						</li>
						<li><strong>Automated technologies or interactions:</strong> As you use our site, we may automatically collect Technical Data about your equipment, browsing actions and usage patterns. We collect this data by using cookies, server logs and similar technologies. We may also receive Technical Data about you if you visit other websites that use our cookies.</li>
						<li><strong>Third parties or publicly available sources:</strong> We may receive personal data about you from various third parties and public sources as set out below.<ul>
										<li><strong>Robo Voucher, Convert Kit and Ovatu</strong>
												<ul>
														<li>Analytics providers such as Google, based outside the EU.</li>
														<li>Advertising networks Facebook, Instagram based outside the EU.</li>
														<li>Search information providers.</li>
														<li>Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as paypal, stripe, Robovoucher and Ovatu</li>
														<li>Identity and Contact Data from data brokers or aggregators</li>
														<li>Identity and Contact Data from publicly available sources such as Companies House and the Electoral Register based inside the EU.</li>
														<li>Ovatu booking system.</li>
												</ul>
										</li>
								</ul>
						</li>
				</ul>
				<h2>4. How we use your personal data</h2>
				<p>We will only use your personal data when legally permitted. The most common uses of your personal data are:</p>
				<ul>
						<li>Where we need to perform the contract between us.</li>
						<li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
						<li>Where we need to comply with a legal or regulatory obligation.</li>
				</ul>
				<p>Generally, we do not rely on consent as a legal ground for processing your personal data other than in relation to sending marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by emailing us at <a href="mailto:hello@birdsofbeautyparlour.co.uk">hello@birdsofbeautyparlour.co.uk</a>.</p>
				<h3>Purposes for processing your personal data</h3>
				<p>Set out below is a description of the ways we intend to use your personal data and the legal grounds on which we will process such data. We have also explained what our legitimate interests are where relevant. We may process your personal data for more than one lawful ground, depending on the specific purpose for which we are using your data. Please email us at <a href="mailto:hello@birdsofbeautyparlour.co.uk">hello@birdsofbeautyparlour.co.uk</a> if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
					<div style={{overflowX: 'auto'}}>
						<table>
							<thead>
										<tr>
												<th scope="col">
														Purpose/Activity
												</th>
												<th scope="col">
														Type of data
												</th>
												<th scope="col">
														Lawful basis for processing
												</th>
										</tr>
								</thead>
								<tbody>
										<tr>
												<td>
														To register you as a new customer
												</td>
												<td>
														(a) Identity (b) Contact
												</td>
												<td>
														Performance of a contract with you
												</td>
										</tr>
										<tr>
												<td>
														To process and deliver your order including (a) Manage payments, fees and charges (B) Collect and recover money owed to us
												</td>
												<td>
														(a) Identity (b) Contact (c) Financial (d) Transaction (e) Marketing and Communications
												</td>
												<td>
														(a) Performance of a contract with you (b) Necessary for our legitimate interests to recover debts owed to us
												</td>
										</tr>
										<tr>
												<td>
														To manage our relationship with you which will include (a) Notifying you about changes to our terns or privacy policy (b) Asking you to leave a review or take a survey
												</td>
												<td>
														(a) Identity (b) Contact (c) Profile (d) Marketing and Communications
												</td>
												<td>
														(a) Performance of a contract with you (b) Necessary to comply with a legal obligation (c) Necessary for our legitimate interests to keep our records updated and to study how customers use our products/services
												</td>
										</tr>
										<tr>
												<td>
														To enable you to partake in a prize draw, competition or complete a survey
												</td>
												<td>
														(a) Identity (b) Contact (c) Profile (d) Usage (e) Marketing and Communications
												</td>
												<td>
														(a) Performance of a contract with you (b) Necessary for our legitimate interests to study how customers use our products/service. To develop them and grow our business.
												</td>
										</tr>
										<tr>
												<td>
														To administer and protect our business and our site (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data).
												</td>
												<td>
														(a) Identity (b) Contact (c) Technical
												</td>
												<td>
														(a) Necessary for our legitimate interests for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise (b) Necessary to comply with a legal obligation.
												</td>
										</tr>
										<tr>
												<td>
														To deliver relevant content and advertisements to you and measure and understand the effectiveness of our advertising
												</td>
												<td>
														(a) Identity (b) Contact (c) Profile (d) Usage (e) Marketing and Communications (f) Technical
												</td>
												<td>
														Necessary for our legitimate interests to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy
												</td>
										</tr>
										<tr>
												<td>
														To use data analytics to improve our website, products/services, marketing, customer relationships and experiences
												</td>
												<td>
														(a) Technical (b) Usage
												</td>
												<td>
														Necessary for our legitimate interests to define types of customers for our products and services, to keep our site updated and relevant, to develop our business and to inform our marketing strategy
												</td>
										</tr>
										<tr>
												<td>
														To make suggestions and recommendations to you about goods or services that at be of interest to you
												</td>
												<td>
														(a) Identity (b) Contact (c) Technical (d) Usage (e) Profile
												</td>
												<td>
														Necessary for our legitimate interests to develop our products/services and grow our business
												</td>
										</tr>
								</tbody>
						</table>
					</div>
				<h3>Marketing communications</h3>
				<p>You will receive marketing communications from us if you have:</p>
				<ul>
						<li>Requested information from us or purchased goods or services from us; or</li>
						<li>If you provided us with your details and ticked the box at the point of entry of your details for us to send you marketing communications; and</li>
						<li>In each case, you have not opted out of receiving that marketing.</li>
				</ul>
				<p>We will get your express opt-in consent before we share your personal data with any third party for marketing purposes. You can ask us or third parties to stop sending you marketing messages at any time (by logging into the website and checking or unchecking relevant boxes to adjust your marketing preferences) OR (by following the opt-out links on any marketing message sent to you) OR (by emailing us at <a href="mailto:hello@birdsofbeautyparlour.co.uk">hello@birdsofbeautyparlour.co.uk</a>) at any time. Where you opt out of receiving our marketing communications, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions. </p>
				<h3>Change of purpose</h3>
				<p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to find out more about how the processing for the new purpose is compatible with the original purpose, please email us at <a href="mailto:hello@birdsofbeautyparlour.co.uk">hello@birdsofbeautyparlour.co.uk</a>. If we need to use your personal data for a purpose unrelated to the purpose for which we collected the data, we will notify you and we will explain the legal ground of processing. We may process your personal data without your knowledge or consent where this is required or permitted by law.</p>
				<h2>5. Disclosures of your personal data</h2>
				<p>We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.</p>
				<ul>
						<li>Service providers who provide IT and system administration services.</li>
						<li>Professional advisers including lawyers, bankers, auditors and insurers who provide consultancy, banking, legal, insurance and accounting services.</li>
						<li>HM Revenue &amp; Customs, regulators and other authorities based in the United Kingdom and other relevant jurisdictions who require reporting of processing activities in certain circumstances.</li>
						<li>Third parties to whom we sell, transfer, or merge parts of our business or out assets.</li>
				</ul>
				<p>We require all third parties to whom we transfer your data to respect the security of your personal data and to treat it in accordance with the law. We only allow such third parties to process your personal data for specified purposes and in accordance with our instructions. </p>
				<h2>6. International transfers</h2>
				<p>We share your personal data within our group of companies which involves transferring your data outside the European Economic Area (EEA). Countries outside of the European Economic Area (EEA) do not always offer the same levels of protection to your personal data, so European Law has prohibited transfers of personal data outside of the EEA unless the transfer meets certain criteria. Many of our third parties service providers are based outside the European Economic Area (EEA) so their processing of your personal data will involve a transfer of data outside the EEA. Whenever we transfer your personal data out of the EEA, we do our best to ensure a similar degree of security of data by ensuring at least one of the following safeguards is implemented.</p>
				<ul>
						<li>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission; or</li>
						<li>Where we use certain service providers, we may use specific contracts or codes of conduct or certification mechanisms approved by the European Commission which give personal data the same protection it has in Europe; or</li>
						<li>Where we use providers based in the United States, we may transfer data to them if they are part of the EU-US Privacy Shield which requires then to provide similar protection to personal data shared between Europe and the US.</li>
				</ul>
				<h2>7. Data Security</h2>
				<p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know such data. They will only process your personal data on our instructions, and they are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
				<h2>8. Data Retention</h2>
				<p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for persona data, we consider the amount, nature, and sensitivity of the personal data and whether we can achieve those purposes through other means, and the applicable legal requirements. By law we have to keep basic information about our customers (including Contact, Identity, Financial, and Transaction Data) for six years after they cease being customers for tax purposes. In some circumstances you can ask us to delete your data: see below for further information. In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>
				<h2>9. Your legal rights</h2>
				<p>Under certain circumstances, you have rights under data protection laws in relation to your personal data. These include the right to:</p>
				<ul>
						<li>Request access to your personal data</li>
						<li>Request correction of your personal data</li>
						<li>Request erasure of your personal data</li>
						<li>Object to processing of your personal data</li>
						<li>Request restriction of processing your personal data</li>
						<li>Request transfer of your personal data</li>
						<li>Right to withdraw consent</li>
				</ul>
				<p>You can see more about these rights at <a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</a>. If you wish to exercise any of the rights set out above, please email us at <a href="mailto:hello@birdsofbeautyparlour.co.uk">hello@birdsofbeautyparlour.co.uk</a>. You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances. We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response. We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
				<h2>10. Third-party links</h2>
				<p>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</p>
				<h2>11. Cookies</h2>
				<p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.</p>
			</div>
		</section>
	</Layout>
)